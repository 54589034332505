<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-staff')"
    route="staff"
    auto-complete-id="staff"
    :table-attributes="tableAttributes"
    :data-list-config="dataListConfig"
    :default-sort="{property: 'no', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :custom-filters="customFilters"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {Staff} from '../../../models';
import {useUserProfileStore} from '../../../store';
export default {
  name: 'HitStaffBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    const user = useUserProfileStore();
    return {t, user};
  },
  computed: {
    customFilters() {
      const filters = {};
      return filters;
    },

    tableAttributes() {
      return this.user.ownsModule('staff-base')
        ? Staff.browserDisplayAttrs
        : Staff.confidentialBrowserDisplayAttrs;
    },
  },
  data() {
    return {
      dataListConfig: {
        zone2Field: 'name',
      },
    };
  },
  methods: {
    selectItem(data) {
      const staff = new Staff(data);
      this.$emit('itemSelected', staff);
    },
  },
  emits: ['itemSelected'],
};
</script>
