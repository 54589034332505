import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';
import {useConfigurationStore} from '../../store';
import {HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {DataService} from '../../api';

const DOC_ENTITY_FKS = [
  'address_id',
  'staff_id',
  'equipment_id',
  'resource_id',
  'project_id',
  'project_part_id',
  'form_id',
  'transaction_id',
  'action_id',
  'purchase_id',
];

export default class BaseModel {
  static IGNORE_FOR_FAST_MODE_BROWSER = [15];

  constructor(id, number, designation) {
    this.id = id;
    this.number = number;
    this.designation = designation;
  }

  static get attrsToStoreInForm() {
    return ['number', 'designation'];
  }
  get attrsToStoreInForm() {
    return BaseModel.attrsToStoreInForm;
  }

  static get joinFilterColumns() {
    return ['no', 'designation'];
  }
  get joinFilterColumns() {
    return BaseModel.joinFilterColumns;
  }

  static get config() {
    return {
      addable: true,
      duplicatable: true,
      importable: true,
      browsable: true,
      deletable: true,
    };
  }

  get config() {
    return BaseModel.config;
  }

  /**
   * Define print template prefix in child class if entity is printable
   */
  static get printTemplateType() {
    return undefined;
  }
  get printTemplateType() {
    return BaseModel.printTemplateType;
  }

  /**
   * The full designation is per default <no - designation> or <designation>
   * depending on the value of no. If entity has other convention -> override
   */
  get fullDesignation() {
    return HitUtils.createStandardDesignation(this);
  }

  /**
   * The entity designation is only the designation part of the entity and not
   * with the linked parts from an eventual parent entity (as for project parts)
   */
  get entityDesignation() {
    return this.fullDesignation;
  }

  /**
   * Name of the table the entity belongs to
   */
  static get apiRoute() {
    throw Error('API route needs to be defined in the child class');
  }
  get apiRoute() {
    throw Error('API route needs to be defined in the child class');
  }

  /**
   * When an entity is used in another table, this is the default name of the FK
   * If another name is used, set the fkToUpdate prop in the attribute
   */
  static get fkName() {
    throw Error('Foreign key name needs to be defined in the child class');
  }
  get fkName() {
    throw Error('Foreign key name needs to be defined in the child class');
  }

  static get listPageRoute() {
    throw Error('List page route must be defined in the child class');
  }
  get listPageRoute() {
    throw Error('List page route must be defined in the child class');
  }

  /**
   * Name of the view the user is redirected to if he requests details about the entity
   */
  static get detailPageRoute() {
    throw Error('Detail page must be defined in the child class');
  }
  get detailPageRoute() {
    throw Error('Detail page must be defined in the child class');
  }

  /**
   * Initial values for the columns if the entity is created via the creation
   * button in the floating panel
   */
  static get createInitColumns() {
    return {
      designation: '',
    };
  }

  /**
   * Initial values for the columns if the entity is created via the creation
   * button in the floating panel
   * We also need to define a non-static getter -> ex.: projectPart needs to set
   * the project_id during creation -> needs to access instance of the class
   */
  get createInitColumns() {
    return BaseModel.createInitColumns;
  }

  /**
   * When we join an entity during fetch process, we do not always want to get all
   * the values from the entity. Per default = all.
   * TO IMPROVE PERFORMANCE -> Return only a list of attributes to limit join
   */
  static get joinAttrsKey() {
    throw Error('Define the attributes for the join in the child class');
  }

  static get orderKey() {
    return 'no';
  }

  static get listDisplayAttrs() {
    throw Error(
      'Define the default list display attributes in the child class'
    );
  }

  static get entityListDisplayAttrs() {
    throw Error(
      'Define the default entity list display attributes in the child class'
    );
  }

  static get detailAttrs() {
    throw Error('Define the detail attributes in the child class');
  }

  static get browserDisplayAttrs() {
    throw Error(
      'Define the attributes that need to be loaded in the entity browser in the child class'
    );
  }

  static get confidentialBrowserDisplayAttrs() {
    return this.browserDisplayAttrs;
  }

  static getAttributes(displayAttributes = [], fetchAttributes = []) {
    const attributes = {};
    const allAttrs = this.allAttributes;
    displayAttributes.forEach((aKey) => {
      attributes[aKey] = allAttrs[aKey];
    });
    fetchAttributes.forEach((aKey) => {
      const att = allAttrs[aKey];
      att.tableProperties = {};
      attributes[aKey] = att;
    });
    return attributes;
  }

  static get allAttributes() {
    throw Error(
      `The attributes of the model need to be defined in the child class`
    );
  }

  /**
   * Generates a list with all the columns that are also fetched with the modal browser
   * Like this, even if the data is not displayed, it is returned when an entity has been selected
   * Important for the form functionality
   */
  static entityColumns(attributes = this.browserDisplayAttrs) {
    let attributeString = '';
    let fetchedIdColumn = false;
    for (let key in attributes) {
      const attribute = attributes[key];
      if (BaseModel.IGNORE_FOR_FAST_MODE_BROWSER.includes(attribute.dataType)) {
        continue;
      }
      if (attribute.dataType > 100) {
        let fkColumns = '';
        attribute.joinPath.forEach((join) => {
          fkColumns += join + '(';
        });
        fkColumns += attribute.dataObjectClass.entityColumns(
          attribute.dataObjectClass.getAttributes(
            attribute.dataObjectClass.joinAttrsKey
          )
        );
        fkColumns = fkColumns + ')'.repeat(attribute.joinPath.length);
        attributeString += `${key}:${fkColumns},`;
      } else {
        const column = attribute.column ? attribute.column : key;
        if (column === 'id') fetchedIdColumn = true;
        attributeString += `${key}:${column},`;
      }
    }
    if (!fetchedIdColumn) attributeString += 'id:id,';
    return attributeString.slice(0, -1);
  }

  /**
   * Generates the given attribute in all the selected languages of the company
   */
  static generateMultiLanguageField({key, type, header, column = null}) {
    const attributes = {};
    const languages = useConfigurationStore().companyLanguagesObject;
    languages.forEach((lan) => {
      let headerValue = header;
      if (languages.length > 1) {
        headerValue = {
          message: `${header}-localised`,
          payload: {
            locale: lan.shortDesignation,
          },
        };
      }
      attributes[`${key}${lan.valueSuffix}`] = new HitContainerAttribute(type, {
        column: `${column || key}_${lan.hitI18nCode}`,
        validations: {required},
        formItemName: key,
        tableProperties: {
          header: headerValue,
          maxWidth: '1fr',
        },
      });
    });
    return attributes;
  }

  /**
   * Generates the attribute name for the user language
   */
  static attributeUserLocale(attributePrefix, snakeCase = false) {
    let userLanguage;
    if (snakeCase) {
      userLanguage = '_' + useConfigurationStore().userLanguageSnakeCase;
    } else {
      userLanguage = useConfigurationStore().userLanguagePascalCase;
    }
    return `${attributePrefix}${userLanguage}`;
  }

  /**
   * Generates the attribute name for the company language
   */
  static attributeCompanyLocale(attributePrefix, snakeCase = false) {
    let companyLanguage;
    if (snakeCase) {
      companyLanguage = '_' + useConfigurationStore().mainLanguageSnakeCase;
    } else {
      companyLanguage = useConfigurationStore().mainLanguagePascalCase;
    }
    return `${attributePrefix}${companyLanguage}`;
  }

  static attributesAllCompanyLanguages(attributePrefix, snakeCase = false) {
    const attributes = [];
    useConfigurationStore().companyLanguagesObject.forEach((lan) => {
      attributes.push(
        snakeCase
          ? `${attributePrefix}_${lan.hitI18nCode}`
          : `${attributePrefix}${lan.valueSuffix}`
      );
    });
    return attributes;
  }

  static factory() {
    throw Error(
      `Factory method should be implemented in each subclass of BaseHitModel: ${this.name}`
    );
  }
  static fromJson(json) {
    let result = this.factory();
    json = this.$transformJson(json);
    result = Object.assign(result, json);
    return result;
  }

  static $transformJson(json) {
    Object.keys(json).forEach((property) => {
      // Change dates to Date type instead of String
      if (property.toUpperCase().endsWith('DATE')) {
        json[property] = new Date(json[property]);
      }
    });

    return json;
  }

  /**
   * Keep them here -> form store still uses them
   */
  static getMTMRelations() {
    return {};
  }

  static getMTORelations() {
    return {};
  }

  static getIdColumn() {
    return 'id';
  }

  /**
   * Needs to be done in separate function to be able to overwrite function in
   * case of project part -> list view is the detail view of the project
   */
  redirectToList(router) {
    router.push({
      name: this.listPageRoute,
    });
  }

  async delete(router = undefined) {
    if (DOC_ENTITY_FKS.includes(this.fkName)) {
      await DataService.update(
        'document',
        {[this.fkName]: `eq.${this.id}`},
        {[this.fkName]: null}
      );
    }
    await DataService.delete(this.apiRoute, {
      id: `eq.${this.id}`,
    });
    if (router) {
      this.redirectToList(router);
    }
  }
}
