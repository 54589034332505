import BaseModel from './BaseModel';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {HitLanguageUtils} from '../../utils';
import {required} from '@vuelidate/validators';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
import {translate} from '../../plugins/i18n/i18n';
import {Resource} from './index';

export default class Staff extends BaseModel {
  constructor({
    id,
    number,
    userId,
    name = undefined,
    firstName = '',
    lastName = '',
    active,
    gender = undefined,
    houseNo = undefined,
    boxNo = undefined,
    postcode = undefined,
    city = undefined,
    country = undefined,
    memo = undefined,
    resource = undefined,

    // We also need to add the key how it is stored in the db (we use the db column names)
    no = undefined,
    designation = undefined,
  }) {
    super(id, number || no, name || designation);
    this.name = name;
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.active = active;
    this.gender = gender
      ? HitOnlineEnums.getEnumValue('GENDER', gender, translate)
      : null;
    this.houseNo = houseNo;
    this.boxNo = boxNo;
    this.postcode = postcode;
    this.city = city;
    this.country = country;
    this.memo = memo;
    this.resource = resource ? new Resource(resource) : null;
  }

  get fullDesignation() {
    return HitUtils.createStandardDesignation({
      number: this.number,
      designation:
        this.firstName || this.lastName
          ? `${this.firstName} ${this.lastName}`
          : this.designation,
    });
  }

  static get fkName() {
    return 'staff_id';
  }
  static get apiRoute() {
    return 'staff';
  }
  static get detailPageRoute() {
    return 'staffDetail';
  }
  static get listPageRoute() {
    return 'staffList';
  }
  get fkName() {
    return Staff.fkName;
  }
  get apiRoute() {
    return Staff.apiRoute;
  }
  get listPageRoute() {
    return Staff.listPageRoute;
  }
  get detailPageRoute() {
    return Staff.detailPageRoute;
  }

  static get printTemplateType() {
    return 'staff';
  }
  get printTemplateType() {
    return Staff.printTemplateType;
  }

  static get attrsToStoreInForm() {
    return ['number', 'designation', 'firstName', 'lastName', 'resource'];
  }
  get attrsToStoreInForm() {
    return BaseModel.attrsToStoreInForm;
  }

  static get joinAttrsKey() {
    return [
      'userId',
      'number',
      'name',
      'firstName',
      'lastName',
      'locale',
      'resource',
    ];
  }

  static get createInitColumns() {
    // We need to define the column names of the db and not the attribute keys
    return {
      no: '',
      first_name: '',
      last_name: '',
      birthdate: new Date(),
      civilstatus_date: new Date(),
      idcard_date: new Date(),
      entry_date: new Date(),
      exit_date: new Date(),
      drivinglicence_date: new Date(),
    };
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      'name',
      'gender',
      'street',
      'houseNo',
      'boxNo',
      'postcode',
      'city',
      'country',
      'active',
      'tags',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      [
        'number',
        'name',
        'street',
        'houseNo',
        'boxNo',
        'postcode',
        'city',
        'country',
        'active',
        'tags',
      ],
      ['firstName', 'lastName', 'userId', 'resource']
    );
  }

  static get confidentialBrowserDisplayAttrs() {
    return this.getAttributes(
      ['number', 'name', 'active', 'tags'],
      [
        'firstName',
        'lastName',
        'userId',
        'resource',
        'street',
        'houseNo',
        'boxNo',
        'postcode',
        'city',
        'country',
      ]
    );
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      userId: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'user_id',
        tableProperties: {
          header: 'hit-app.common.user-id',
          maxWidth: '1fr',
        },
      }),
      name: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'designation',
        tableProperties: {
          header: 'hit-base.common.name',
          sharable: true,
          width: '1fr',
        },
      }),
      firstName: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'first_name',
        validations: {required},
        tableProperties: {
          header: 'hit-app.common.first-name',
          maxWidth: '1fr',
        },
      }),
      lastName: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'last_name',
        validations: {required},
        tableProperties: {
          header: 'hit-app.common.last-name',
          maxWidth: '1fr',
        },
      }),
      street: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.street',
          maxWidth: '1fr',
        },
      }),
      houseNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'house_no',
        tableProperties: {
          header: 'hit-app.common.house-number',
          sharable: true,
          width: '1fr',
        },
      }),
      boxNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'box_no',
        tableProperties: {
          header: 'hit-app.common.box-number',
          sharable: true,
          width: '1fr',
        },
      }),
      postcode: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.postcode',
          maxWidth: '0.5fr',
        },
      }),
      city: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.city',
          maxWidth: '1fr',
        },
      }),
      country: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_COUNTRY, {
        joinPath: ['country!fk_staff_country_country'],
        tableProperties: {
          header: 'hit-base.common.country',
          maxWidth: '1fr',
        },
      }),
      locale: new HitContainerAttribute(ATTRIBUTE_TYPES.LANGUAGE, {
        tableProperties: {
          header: 'hit-base.common.language',
          maxWidth: '1fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        column: {
          table: 'tag!staff_tag',
        },
        tableProperties: {
          header: 'hit-base.common.tags',
          maxWidth: '2fr',
        },
      }),
      planning: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.timeslot.planning-flag',
        },
      }),
      resource: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        joinPath: ['resource!fk_staff_cost_resource_id_resource'],
        fkToUpdate: 'cost_resource_id',
        formItemName: 'resource',
        tableProperties: {
          header: 'hit-app.common.cost-resource',
        },
      }),
      roles: new HitContainerAttribute(ATTRIBUTE_TYPES.ARRAY, {
        column: 'qualification!staff_qualification(id)',
        transform: (objArray) => objArray.map((x) => x.id),
        tableProperties: {
          header: 'hit-base.common.role',
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
      gender: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'GENDER',
        tableProperties: {
          header: 'hit-app.common.gender',
          sharable: true,
        },
      }),
      birthdate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        tableProperties: {
          header: 'hit-app.common.birthdate',
          sharable: true,
        },
      }),
      birthplace: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.birthplace',
          sharable: true,
        },
      }),
      registrationNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'registration_no',
        tableProperties: {
          header: 'hit-app.common.nationalregisterid',
          sharable: true,
        },
      }),
      citizenship: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_COUNTRY, {
        joinPath: ['country!fk_staff_country_id_country'],
        column: 'country_id',
        fkToUpdate: 'country_id',
        tableProperties: {
          header: 'hit-app.common.citizenship',
          sharable: true,
        },
      }),
      civilStatus: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'civilstatus',
        tableProperties: {
          header: 'hit-app.common.civilstatus',
          sharable: true,
        },
      }),
      civilStatusDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'civilstatus_date',
        tableProperties: {
          header: 'hit-app.common.civilstatusdate',
          sharable: true,
        },
      }),
      personOnCharge: new HitContainerAttribute(ATTRIBUTE_TYPES.INT, {
        column: 'dependants',
        tableProperties: {
          header: 'hit-app.common.persononcharge',
          sharable: true,
        },
      }),
      idCardNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'idcard_no',
        tableProperties: {
          header: 'hit-app.common.idcardno',
          sharable: true,
        },
      }),
      idCardDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'idcard_date',
        tableProperties: {
          header: 'hit-app.common.expirationdate',
          sharable: true,
        },
      }),
      drivingLicenceNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'drivinglicence_no',
        tableProperties: {
          header: 'hit-app.common.drivinglicenceno',
          sharable: true,
        },
      }),
      drivingLicenceDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'drivinglicence_date',
        tableProperties: {
          header: 'hit-app.common.expirationdate',
          sharable: true,
        },
      }),
      drivingLicenceCategory: new HitContainerAttribute(
        ATTRIBUTE_TYPES.STRING,
        {
          column: 'drivinglicence_category',
          tableProperties: {
            header: 'hit-app.common.drivinglicencecategory',
            sharable: true,
          },
        }
      ),
      emergencyContact: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'emergency_contact',
        tableProperties: {
          header: 'hit-app.common.emergencycontact',
          sharable: true,
        },
      }),
      civilInfoComment: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'civilstatus_comment',
        tableProperties: {
          header: 'hit-app.common.civilinfocomment',
          sharable: true,
        },
      }),
      bankName: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'bank_name',
        tableProperties: {
          header: 'hit-app.common.bankname',
        },
      }),
      iban: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'iban',
        tableProperties: {
          header: 'hit-app.common.iban',
          sharable: true,
        },
      }),
      bic: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'bic',
        tableProperties: {
          header: 'hit-app.common.bic',
          sharable: true,
        },
      }),
      bankAccountNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'bank_account_no',
        tableProperties: {
          header: 'hit-app.common.bankaccountno',
          sharable: true,
        },
      }),
      professionalProfile: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'profession',
        tableProperties: {
          header: 'hit-app.common.professionalprofile',
          sharable: true,
        },
      }),
      engagementDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'entry_date',
        tableProperties: {
          header: 'hit-app.common.engagementdate',
          sharable: true,
        },
      }),
      redundancyDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'exit_date',
        tableProperties: {
          header: 'hit-app.common.redundancydate',
          sharable: true,
        },
      }),
      redundancyReason: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'exit_reason',
        tableProperties: {
          header: 'hit-app.common.redundancyreason',
          sharable: true,
        },
      }),
      professionalProfileComment: new HitContainerAttribute(
        ATTRIBUTE_TYPES.STRING,
        {
          column: 'exit_comment',
          tableProperties: {
            header: 'hit-app.common.professionalprofilecomment',
            sharable: true,
          },
        }
      ),
      sizeJacket: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'jacket_size',
        tableProperties: {
          header: 'hit-app.common.sizejacket',
          sharable: true,
        },
      }),
      sizeTrousers: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'trousers_size',
        tableProperties: {
          header: 'hit-app.common.sizetrousers',
          sharable: true,
        },
      }),
      sizeShoes: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'shoe_size',
        tableProperties: {
          header: 'hit-app.common.size-shoes',
          sharable: true,
        },
      }),
    };
  }
}
