import {BaseModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';

export default class ResourceData extends BaseModel {
  constructor({id, number, designation}) {
    super(id, number, designation);
  }

  static get fkName() {
    return undefined;
  }
  static get apiRoute() {
    return 'resource_data';
  }
  static get detailPageRoute() {
    return undefined;
  }
  static get listPageRoute() {
    return undefined;
  }
  get fkName() {
    return ResourceData.fkName;
  }
  get apiRoute() {
    return ResourceData.apiRoute;
  }
  get listPageRoute() {
    return ResourceData.listPageRoute;
  }
  get detailPageRoute() {
    return ResourceData.detailPageRoute;
  }

  static get joinAttrsKey() {
    return ['number', 'designation', 'percentage'];
  }

  static get entityListDisplayAttrs() {
    return [
      'designation',
      'unit',
      'quantity',
      'netPrice',
      'grossPrice',
      'comment',
    ];
  }

  static get entityListHiddenAttrs() {
    return [
      'resourceOrigin',
      'resourceTarget',
      'composite',
      'unfold',
      'customisable',
      'margin',
      'active',
      'netAmount',
      'grossAmount',
      'quantityCalculated',
      'validExpression',
    ];
  }

  static get entityListAttrs() {
    return this.getAttributes(
      this.entityListDisplayAttrs,
      this.entityListHiddenAttrs
    );
  }

  static get allAttributes() {
    return {
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          mainSearch: true,
          maxWidth: '1.5fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '3.5rem',
        },
      }),
      resourceTarget: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        column: 'resource_id',
        joinPath: ['resource!fk_resource_data_resource_id_resource'],
        fkToUpdate: 'resource_id',
        tableProperties: {
          header: 'hit-base.common.resource',
        },
      }),
      resourceOrigin: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        column: 'data_resource_id',
        joinPath: ['resource!fk_resource_data_data_resource_id_resource'],
        fkToUpdate: 'data_resource_id',
        tableProperties: {
          header: 'hit-base.common.resource',
        },
      }),
      unit: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_UNIT, {
        joinPath: ['unit!fk_resource_data_unit_id_unit'],
        tableProperties: {
          header: 'hit-base.common.unit',
          maxWidth: '0.5fr',
        },
      }),
      customisable: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'customisable',
        tableProperties: {
          header: 'hit-app.common.customisable',
        },
      }),
      composite: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'composite',
          header: 'hit-app.resource.composite',
          maxWidth: '1fr',
        },
      }),
      unfold: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.resource.unfold',
        },
      }),
      quantity: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 3,
        decimalDefaultValue: 0,
        tableProperties: {
          header: 'hit-app.common.quantity',
        },
      }),
      quantityExpression: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'quantity_expression',
        tableProperties: {
          header: 'hit-app.transaction.expression',
        },
      }),
      validExpression: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'valid_expression',
      }),
      quantityCalculated: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'quantity_calculated',
      }),
      netPrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'net_price',
        decimalPrecision: 2,
        decimalDefaultValue: 0.0,
        tableProperties: {
          header: 'hit-app.common.net-price',
          maxWidth: '0.5fr',
        },
      }),
      netAmount: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        column: 'net_amount',
        readOnly: true,
        default: 0.0,
        tableProperties: {
          header: 'hit-app.common.net-amount',
          maxWidth: '0.65fr',
        },
      }),
      grossPrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        column: 'gross_price',
        default: 0.0,
        tableProperties: {
          header: 'hit-app.common.gross-price',
          maxWidth: '0.5fr',
        },
      }),
      grossAmount: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        column: 'gross_amount',
        default: 0.0,
        readOnly: true,
        tableProperties: {
          header: 'hit-app.common.gross-amount',
          maxWidth: '0.65fr',
        },
      }),
      margin: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 4,
        decimalDefaultValue: 1,
        tableProperties: {
          header: 'hit-app.common.base-margin',
        },
      }),
      comment: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.comment',
          maxWidth: '1fr',
        },
      }),
    };
  }
}
